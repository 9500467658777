import React from 'react';

import FooterLogo from '../../shared/components/footer-logo';
import withPageComponents from '../../page/components/with-page-components';

import './partner-logos.less';

const MDPLogos = ({ mdpHeader }) => {
    const { logos } = mdpHeader || {};
    if (!logos || logos.length < 1) {
        return null;
    }
    return <FooterLogo className="mdp-logos" logos={logos} />;
};

export default withPageComponents(MDPLogos, { components: ['mdpHeader'] });
