import React, { useState, useCallback } from 'react';
import { Tabs, Typography } from '@hpstellar/core';
import { useSmoothScroll } from '@hpstellar/core/hooks';
import { Tabs as VisIdTabs, TabList } from '@hpstellar/core/visId';

import withPageComponents from '../../../page/components/with-page-components';
import useScrollTarget from '../../../hooks/useScrollTarget';
import { withError } from '../../../shared/components/error-boundary';
import { Helpers } from '../../../core/src/helpers';
import useTemplateTheme from '../../../hooks/useTemplateTheme';

const MIN_HEIGHT = 90;
import './sticky-nav.less';

export const DEFAULT_TAB_ORDER = ['features', 'buy'];

const LIST = [
    {
        tab: {
            gtmValue: 'buy',
            title: 'Products',
            hash: 'buy',
            id: 'vao',
        },
    },
    {
        tab: {
            gtmValue: 'features',
            title: 'Features',
            hash: 'features',
            id: 'fea',
        },
    },
];

const getTabList = Helpers.memoize(
    order => {
        //sort LIST by order
        if (!order || !order.length) return LIST;
        return order.map(tab => {
            return LIST.find(t => t.tab.hash === tab);
        });
    },
    order => (order || []).length,
);

/**
 * Adds hidden elements to help support rich content that has clicks targeted ids from legacy MDP pages
 */
const ScrollClickTargets = withError(({ list = [], onClick = () => {} }) => {
    return (
        <div style={{ display: 'none' }}>
            {list.map(({ tab }, idx) => {
                return (
                    <div
                        id={tab.id}
                        onClick={() => {
                            onClick(idx);
                        }}
                    ></div>
                );
            })}
        </div>
    );
});

const StickyNAV = ({ mdpProductList, mdpRichContent, width }) => {
    const { tabs, css } = useTemplateTheme(['tabs']);
    const { order = DEFAULT_TAB_ORDER } = tabs || {};
    const { scrollToTarget } = useSmoothScroll({ offset: MIN_HEIGHT });
    const [activeIndex, setActiveLink] = useState(0);
    const tabList = getTabList(order);
    useScrollTarget(
        'buy',
        undefined,
        lastKnownScrollPosition => {
            let index = 0;
            tabList.forEach(({ tab }, idx) => {
                let anchorElem = document.getElementById(tab.hash);
                if (anchorElem && lastKnownScrollPosition > anchorElem.offsetTop) {
                    index = idx;
                }
            });
            setActiveLink(index);
        },
        MIN_HEIGHT * 2,
    );
    const handleTabClick = useCallback(
        scrollToTarget => index => {
            const { tab } = tabList[index] || {};
            if (tab.hash) {
                try {
                    scrollToTarget(document.getElementById(tab.hash));
                    const hashId = `#${tab.hash}`;
                    if (history.replaceState) {
                        history.replaceState(null, null, hashId);
                    } else {
                        location.hash = hashId;
                    }
                } catch (e) {}
            }
        },
        [tabList],
    );

    const { products, sectionTitle } = mdpProductList || {};
    const { content: richContent } = mdpRichContent || {};
    const hideTabs = (products || []).length < 1 || (richContent || []).length < 1;
    const classes = {
        tabActive: css ? `${css}-tab-active tab-active` : 'tab-active',
        tab: css ? `${css}-tab` : '',
    };

    return (
        <div className={`mdp-nav ${hideTabs ? ' no-border' : ''}`}>
            <ScrollClickTargets list={tabList} onClick={handleTabClick(scrollToTarget)} />
            {!hideTabs && <div className="title-spacer"></div>}
            {sectionTitle && (
                <Typography variant="titleCustom" className="mdp-title">
                    {sectionTitle}
                </Typography>
            )}
            {!hideTabs && (
                <Tabs
                    className={`mdp-tab-list${css ? ` ${css}-brand-tab-list` : ''}`}
                    list={tabList}
                    activeIndex={activeIndex}
                    tabsListProps={{
                        type: 'secondary',
                        size: 'large',
                        onClick: handleTabClick(scrollToTarget),
                        dropdownOnMobile: false,
                        tabTitleTag: 'h3',
                        classes: classes,
                        dense: true,
                    }}
                />
            )}
        </div>
    );
};

export default withPageComponents(StickyNAV, {
    components: ['mdpProductList', 'mdpRichContent'],
    device: true,
});
