import React from 'react';

import MDPProductList from './mdp-product';
import CompareDrawer from '../../compare/components/compare-drawer';
import MDPRichContent from './mdp-rich-content';
import MDPPartnerLogo from './partner-logos';
import MDPStickyNAV, { DEFAULT_TAB_ORDER } from './sticky-nav';
import SuppliesFinder from './mdp-supplies-search';
import ThemeCSSLoader from './theme-css-loader';
import useTemplateTheme, { WithTheme } from '../../hooks/useTemplateTheme';
import withPageComponents from '../../page/components/with-page-components';

import './mdp.less';
import './rich-content.less';

const MDPContent = withPageComponents(
    ({ gqlAsyncClientLoad, mdpRichContent }) => {
        const { assets } = mdpRichContent || {};
        const { tabOrder = DEFAULT_TAB_ORDER } = assets || {};
        const { tabs } = useTemplateTheme(['tabs']);
        const { order = tabOrder } = tabs || {};
        return (
            <>
                {order.map((key, index) => {
                    if (key === 'features') {
                        //skip waiting for dependencies to load if richt content is not the first tab
                        return <MDPRichContent key={key} skip={index === 0 && !gqlAsyncClientLoad} />;
                    } else {
                        return <MDPProductList key={key} tab={key} />;
                    }
                })}
            </>
        );
    },
    { components: ['mdpRichContent'], gqlClientLoadStatus: true },
);

const MDP = ({ vanityUrl }) => {
    /** this wrapping div was a fragment, but for some reason the client graphql fallback did not like that **/
    return (
        <div>
            <div className="mdp">
                <WithTheme CSSLoader={ThemeCSSLoader}>
                    <SuppliesFinder />
                    <MDPStickyNAV />
                    <MDPPartnerLogo />
                    <MDPContent />
                </WithTheme>
            </div>
            <CompareDrawer />
        </div>
    );
};

export default MDP;
