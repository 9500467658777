import React from 'react';

import { PartnerLogos } from '@hpstellar/core';

import withWindowResize from '../../ui/components/window-resize';

import { getPartnerLogoContent } from '../../utility/setHtml';

import './footer-logo.less';

const FooterLogo = ({ logos, width, breakpoint, className }) => {
    if (!isNaN(breakpoint) && width >= breakpoint) return null;
    return (
        <div className={`footerLogo${className ? ` ${className}` : ''}`}>
            <PartnerLogos className="footer-logo-container" logos={getPartnerLogoContent(logos)} />
        </div>
    );
};

export default withWindowResize(FooterLogo);
